{
  "meta": {
    "title": {
      "default": "SMS Traffic",
      "Auth": "Authorization",
      "Dashboard": "Private area",
      "BatchCreate": "Messaging",
      "BatchEdit": "Edit batch",
      "BatchDetails": "Batch details",
      "BatchDetailsArchive": "Archive of batches",
      "Groups": "Subscribers lists",
      "GroupCreate": "Create a list",
      "Drafts": "Drafts",
      "DraftCreate": "Create a draft",
      "Batches": "Scheduled Messages",
      "OperatorByPhone": "Mobile operator checking",
      "TemplatesList": "Templates List",
      "TemplatesCheck": "Templates Check",
      "TemplatesTest": "Templates Test",
      "SendMessages": "Send Messages",
      "MessagesLog": "Messages Log",
      "RequestedReports": "Requested Reports",
      "Statistics": "Statistics",
      "AccountingInfo": "Accounting Details",
      "Accounting": "Accounting documents",
      "TransactionsHistory": "Transactions",
      "SettingsGeneral": "Settings",
      "SettingsSecurity": "Security Settings",
      "SettingsSmtp": "SMTP v.1",
      "SettingsPassword": "Change Password",
      "BatchesArchive": "Scheduled messages archive",
      "Originators": "Senders list",
      "Accounts": "Accounts",
      "Departments": "Departments",
      "EventLogs": "Event log",
      "ForgotPassword": "Reset Password",
      "Registration": "Registration",
      "PhoneConfirmation": "Phone number confirmation",
      "PasswordConfirmation": "Password Confirmation",
      "ToOldFront": "To Old Front",
      "LoginPrivateArea": "Secure login"
    },
    "description": {
      "default": "Efficient digital communications"
    }
  },
  "error": {
    "general": "Unexpected problems have arisen, try again later",
    "network": "Unexpected network problems have arisen, check an internet connection and try again",
    "102": "Access denied",
    "108": "Fill in every mandatory filed and try again",
    "109": "Wrong login or password",
    "110": "Wrong login and password",
    "111": "Password is too insecure, please enter a more complex password",
    "113": "The session lifetime has expired, you need to start over",
    "114": "The secret code is incorrect, try fill again",
    "115": "Exceeded the maximum number of attempts to enter the code, send a request to receive the code again",
    "116": "The code has expired. Try to generate again",
    "117": "Exceeded the maximum number of codes sent to your phone number. Contact our support team",
    "118": "Sorry, password recovery is not available. Contact our support team",
    "119": "Didn't send secret code to reset password",
    "126": "This number is already registered in our system. Contact our support team",
    "127": "The Subscribers list already exists",
    "123": "Email already register",
    "128": "It is impossible to hide the subscriber list",
    "129": "The Subscriber already exists",
    "130": "the Report already generated",
    "131": "The entered password has already been used before, please enter another password",
    "133": "Incorrect phone format",
    "135": "The old paswword does not match",
    "138": "Please fill Captcha",
    "141": "Two-factor authentication is not configured on the account, please contact your account manager",
    "143": "Code already has been sent",
    "404": "Does not exist, please contact support",
    "502": "Incorrect request",
    "600": "File not found",
    "606": "Too many phone numbers for testing",
    "610": "Our system has detected that a duplicate mailing list has been generated from your account, the current request has been cancelled."
  },
  "validation": {
    "required": "Field \"{name}\" is required",
    "password": "The password have to contain at least one number, an uppercase letter and a lowercase letter. Latin letters, numbers and this list of symbols is allowed: !{'@'}#$%^&*()_+",
    "passwordConfirmation": "Entered passwords do not match",
    "number": "Field \"{name}\" must be a number",
    "email": "Not a valid email address",
    "login": "Latin lowercase letters, numbers and the symbol \"_\" are allowed",
    "string": {
      "min": "Minimum number of characters is {number}",
      "max": "Maximum number of characters is {number}"
    },
    "value": {
      "min": "Minimum value is {number}",
      "max": "Maximum value is {number}"
    },
    "timeFormatHHmm": "Please type in format HH:MM",
    "originatorRegExp": "Latin letters, numbers and this list of symbols is allowed: _-",
    "phoneNumber": "Correctly fill in the phone number"
  },
  "gender": {
    "men": "Man",
    "women": "Woman",
    "unknown": "Unknown"
  },
  "units": {
    "count": "time | time | times | times",
    "second": "second | second | seconds | seconds",
    "minute": "minute | minute | minutes | minutes",
    "hour": "hour | hour | hours | hours",
    "day": "day | day | days | days"
  },
  "shortUnits": {
    "second": "sec.",
    "minute": "min."
  },
  "actions": "Actions",
  "login": {
    "title": "Authorization",
    "note": "Make your customer interactions\nmore efficient",
    "form": {
      "login": "Login",
      "password": "Password",
      "submit": "Enter"
    },
    "forgotPassword": "Forgot your password?",
    "noAccount": "I don't have an account yet.",
    "register": "Register",
    "success": "Welcome to personal account"
  },
  "confirmation": {
    "title": "Enter a secret code",
    "note": "SMS confirmation code sent\n to number { phone }.",
    "notCode": "Do not receive a code?",
    "again": "Send again",
    "time": "after"
  },
  "forgotPassword": {
    "forgotTitle": "Reset password",
    "forgotDescription": "Enter the account that will receive an email with password recovery information",
    "form": {
      "login": "Login",
      "captcha": "Captcha",
      "submit": "Recover password"
    },
    "goToLogin": "Go to Login",
    "goBackToLogin": "I remember my details",
    "restoreTitle": "Password recovery",
    "restoreDescription": "A link to reset the password for the \"{login}\" account has been sent to the email "
  },
  "loginPrivateArea": {
    "loginPrivateAreaTitle": "Confirm the logon to the client account «{login}»",
    "form": {
      "submit": "Move to private area"
    }
  },
  "resetPassword": {
    "title": "Change password",
    "form": {
      "password": {
        "placeholder": "Enter new password",
        "label": "Password"
      },
      "passwordRepeated": {
        "placeholder": "Repeat new password",
        "label": "Password repeat"
      },
      "submit": "Confirm changes and login"
    }
  },
  "registration": {
    "title": "Registration",
    "description": "Enter the name of your company and the email address you would like to register with. Our manager will send all the necessary instructions",
    "form": {
      "companyName": "Company name",
      "email": "Email",
      "captcha": "Captcha",
      "personalData": {
        "label": "I have read and agree to the processing of",
        "link": "personal data"
      },
      "submit": "Continue"
    },
    "hasAccount": "I already have an account.",
    "login": "Login",
    "success": {
      "title": "Thanks for your application!",
      "description": "Registration confirmation email has been sent to {email}"
    }
  },
  "sidebar": {
    "menu": {
      "home": "Home",
      "batches": "Messaging",
      "sendMessages": "Send messages",
      "sendLists": "Lists",
      "drafts": "Drafts",
      "scheduledBatches": "Scheduled batches",
      "checkMobileOperator": "Mobile operator checking",
      "templates": "Message templates",
      "templatesList": "List of templates",
      "templatesCheck": "Templates checking",
      "templatesTest": "Templates testing",
      "reports": "Reports",
      "log": "Messages log",
      "statistics": "Statistics",
      "accounting": "Accounting",
      "accountDetails": "Account details",
      "accountsAndInvoices": "Documents",
      "transactionsHistory": "Payment history",
      "settings": "Settings",
      "deliveryAnalysis": "Delivery Analysis",
      "reportsOnRequest": "Report on request"
    }
  },
  "balanceInfo": {
    "title": "Your balance",
    "creditCurrency": "Messages"
  },
  "profileMenu": {
    "openMenu": "Open menu",
    "closeMenu": "Close menu",
    "senderNames": "SMS senders list",
    "accounts": "Accounts list",
    "api": "API and documentation",
    "eventsLog": "Events log",
    "logout": {
      "buttonTitle": "Logout",
      "confirmTitle": "Are you sure you want to exit?",
      "accept": "Logout",
      "cancel": "Cancel"
    }
  },
  "languageSelector": {
    "ru": {
      "short": "РУ",
      "long": "Русский"
    },
    "en": {
      "short": "EN",
      "long": "English"
    }
  },
  "form": {
    "textarea": {
      "characterLimit": "{n} characters left | {n} character left | {n} characters left | {n} characters left",
      "transliteration": "Make translit (RU → EN)",
      "sms": "{count} SMS"
    },
    "multiselect": {
      "placeholder": "Select option",
      "noOptionsText": "No options",
      "noResultsText": "No results found",
      "acceptButtonText": "Select",
      "removeTag": "Remove",
      "selectedText": "{count} selected"
    },
    "from": {
      "time": "Start time",
      "date": "Start date"
    },
    "to": {
      "time": "End time",
      "date": "End date"
    },
    "file": {
      "title": "Drag&drop file here or click to select",
      "delete": "Remove",
      "accept": "File format: {types}",
      "errors": {
        "wrongFormat": "Wrong file format",
        "fileTooLarge": "File is too big"
      }
    },
    "defaultSelectOptionLabel": "All"
  },
  "templatesCheck": {
    "title": "Templates checking",
    "card": "Check the text for a match with some template",
    "label": "Put here your message text",
    "hint": "Check if the potential text of your message matches one of the registered templates. The list of registered templates can be viewed in the previous section",
    "search": "Search",
    "results": "No templates found, please search again | Found {count} similar template | Found {count} similar templates | Found {count} similar templates"
  },
  "templatesTest": {
    "title": "Templates testing",
    "card": "Check the text for a match with potential template",
    "hint": "Check if the potential text of your message matches the template that you want to submit for registration to the mobile operator. The list of previously registered templates can be viewed in the previous section",
    "templateLabel": "Put here your template text",
    "messageLabel": "Put here your message text",
    "submit": "Check",
    "result": {
      "fail": "Message does not match the template",
      "success": "Message successfully match the template"
    }
  },
  "templatesList": {
    "title": "Templates list",
    "downloadButton": "Download templates list in XLS"
  },
  "templatesTable": {
    "message": "Template",
    "operator": "Operator",
    "type": "Template type",
    "originator": "Sender",
    "account": "Account",
    "status": "Status"
  },
  "trafficTypes": {
    "0": "No Type",
    "1": "Advertising",
    "2": "Transaction",
    "3": "Service",
    "4": "International",
    "5": "Authorization",
    "6": "Advertisement templated"
  },
  "templates": {
    "message_downloaded": "Template uploaded",
    "statuses": {
      "to_submit": "Ready to submit",
      "created": "Created",
      "wrong": "Incorrect",
      "conflict": "Duplicate",
      "pending": "Pending",
      "not_submitted": "Submission error",
      "submitted": "Submitted",
      "hold": "On hold",
      "confirmed": "Confirmed",
      "declined": "Declined",
      "error": "Error",
      "test": "Test",
      "deleted": "Deleted"
    }
  },
  "filter": {
    "addButton": "add",
    "add": "Add filter",
    "clear": "Clear all",
    "empty": "Select options you need",
    "drawer": {
      "title": "Filters",
      "submit": "Search",
      "close": "Close filters",
      "apply": "Apply",
      "reset": "Reset"
    },
    "items": {
      "more": "more"
    },
    "fields": {
      "title": "Fields filters",
      "dragHandlerTitle": "Move"
    }
  },
  "pagination": {
    "nextButton": "Next",
    "prevButton": "Previous",
    "jump": "Go to:",
    "pageSize": "Show:",
    "totalItems": "0 results | {count} result | {count} results | {count} results",
    "collapse": "Collapse menu",
    "expand": "Expand menu"
  },
  "noResult": {
    "noResultsFound": {
      "title": "No results",
      "note": "Check input fields <br /> or try to change filters"
    },
    "noData": {
      "title": "No data to display"
    }
  },
  "channels": {
    "sms": "SMS",
    "viber": "Viber",
    "vk": "VK",
    "ok": "OK",
    "whatsapp": "WhatsApp",
    "push": "Push",
    "flashcall": "Flashcall",
    "email": "email"
  },
  "messages": {
    "mt": "Outcoming",
    "mo": "Incoming"
  },
  "all": "All",
  "reloadPage": "Refresh page",
  "groups": {
    "title": "Subscribers lists",
    "create": "Create list",
    "dropdownLabel": "Actions with selected",
    "dropdownItems": {
      "send": "Send",
      "openGroup": "Open list",
      "delete": "Delete"
    },
    "multiplyActions": {
      "delete": {
        "title": "Delete selected lists?",
        "confirm": "Delete",
        "cancel": "Cancel"
      }
    },
    "table": {
      "groupName": "List name",
      "totalPhones": "Subscribers",
      "congratulate": "To wish a happy birthday",
      "creationDate": "Creation date",
      "congratulateVariants": {
        "yes": "Yes",
        "no": "No"
      }
    },
    "delete": {
      "title": "Are you sure you want to delete the list \"{ name }\"",
      "acceptButton": "Delete",
      "cancelButton": "Cancel",
      "success": "The list was successfully deleted"
    },
    "search": {
      "label": "List name",
      "submit": "Search"
    }
  },
  "drafts": {
    "title": "Drafts",
    "table": {
      "name": "Draft name",
      "channel": "Channel",
      "text": "Message",
      "addDate": "Creation date"
    },
    "create": "Create a draft",
    "search": {
      "label": "Draft name",
      "submit": "Search"
    },
    "delete": "Delete selected",
    "tabs": {
      "all": "All"
    }
  },
  "draft": {
    "name": "Name",
    "channel": "Channel",
    "addDate": "Creation date",
    "text": "Message",
    "closeHint": "Close",
    "buttons": {
      "delete": "Delete",
      "send": "Send this draft",
      "edit": "Edit"
    },
    "deleteSingle": {
      "title": "Delete draft \"{name}\"?",
      "buttons": {
        "delete": "Delete",
        "cancel": "Cancel"
      }
    },
    "deleteMultiple": {
      "all": "Are you sure you want to delete all drafts?",
      "exclude": "Are you sure you want to delete all drafts excluding {count} drafts? | Are you sure you want to delete all drafts excluding {count} draft? | Are you sure you want to delete all drafts excluding {count} drafts? | Are you sure you want to delete all drafts excluding {count} drafts?",
      "include": "Are you sure you want to delete {count} draft? | Are you sure you want to delete {count} draft? | Are you sure you want to delete {count} drafts? | Are you sure you want to delete {count} drafts?",
      "buttons": {
        "delete": "Delete",
        "cancel": "Cancel"
      }
    },
    "create": {
      "title": "Create draft",
      "closeHint": "Close",
      "buttons": {
        "create": "Create draft",
        "cancel": "Cancel"
      }
    },
    "form": {
      "name": "Draft name",
      "text": "Message text",
      "channel": "Channel"
    },
    "edit": {
      "title": "Edit draft",
      "closeHint": "Close",
      "buttons": {
        "save": "Save",
        "cancel": "Cancel"
      }
    }
  },
  "operatorByPhone": {
    "title": "Mobile operator checking by phone number",
    "form": {
      "inputPhonePlaceholder": "Phone number",
      "inputPhoneError": "This phone number does not exist. Enter a valid phone number in international format. For example, a Russian network number might look like this: +79031234567",
      "submitButton": "Check"
    },
    "phoneInfo": {
      "phone": "Phone number",
      "operator": "Operator",
      "countryName": "Country",
      "regionName": "Region"
    },
    "unidentifiedPhone": "Failed to determine an operator"
  },
  "accountsAndDepartments": {
    "title": "Accounts and departments",
    "createAccountLink": "Create account",
    "noAccounts": "No accounts",
    "tabs": {
      "accounts": "Accounts list",
      "departments": "Departments list"
    },
    "description": {
      "accounts": "Accounts as units with cross-rights",
      "departments": "Departments list, deprecated"
    },
    "toasts": {
      "accounts": {
        "successDelete": "Account «{ name }» was deleted",
        "successUnblock": "Account «{ name }» was recovered"
      },
      "departments": {
        "successDelete": "Department «{ name }» was deleted",
        "successUnblock": "Department «{ name }» was recovered"
      }
    },
    "modal": {
      "accounts": {
        "title": "Are you sure you want to delete the account \"{ name }\"?"
      },
      "departments": {
        "title": "Are you sure you want to delete the department \"{ name }\"?"
      },
      "acceptButton": "Delete",
      "cancelButton": "Cancel"
    }
  },
  "accountsTable": {
    "columns": {
      "name": "Account name",
      "login": "Login",
      "twoFactorAuth": "2FA",
      "status": "Status",
      "addDate": "Creation date",
      "editDate": "Edit date"
    },
    "status": {
      "active": "Active",
      "deleted": "Deleted",
      "blocked": "Blocked"
    },
    "actions": {
      "openEventLog": "View events log",
      "edit": "Edit",
      "unblock": "Recover",
      "delete": "Delete"
    }
  },
  "departmentsTable": {
    "columns": {
      "name": "Department name",
      "status": "Status",
      "addDate": "Creation date",
      "twoFactorAuth": "2FA",
      "accounts": "Department users"
    },
    "status": {
      "active": "Active",
      "deleted": "Deleted",
      "blocked": "Blocked"
    },
    "actions": {
      "edit": "Edit",
      "unblock": "Recover",
      "delete": "Delete"
    }
  },
  "group": {
    "goToGroups": "Subscribers lists",
    "actions": {
      "title": "Actions with selected",
      "send": "Send",
      "move": "Move to",
      "copy": "Copy to",
      "delete": "Delete",
      "selectList": "Select list"
    },
    "rowActions": {
      "send": "Send",
      "delete": "Delete",
      "edit": "Edit"
    },
    "tabs": {
      "edit": "Edit",
      "settings": "Settings"
    },
    "table": {
      "phone": "Phone",
      "memberName": "Name",
      "gender": "Gender",
      "comment": "Comment",
      "addDate": "Add date",
      "birthday": "Date of birthday"
    },
    "createGroup": {
      "title": "Create a list",
      "success": "List created successfully"
    },
    "settings": {
      "success": "Data changed successfully"
    },
    "groupForm": {
      "groupName": "List name",
      "additional": "Additional options",
      "viewForAll": "The list is visible to all departments",
      "viewOptional": "The list is not visible to the head account",
      "congratulate": {
        "title": "Congratulation",
        "switch": "Happy birthday to subscribers",
        "birthdayMessage": {
          "label": "Congratulation text",
          "placeholder": "Enter congratulation text",
          "hint": "In the body of the message, the [[name]] fields will be replaced with the names of the members of the list",
          "initialValue": "Happy birthday, [[name]]"
        },
        "originator": {
          "label": "Sender name",
          "placeholder": "Enter sender name",
          "validation": "Latin letters, numbers and symbols \"_-\" are allowed"
        },
        "startTime": {
          "label": "Time to send congratulations",
          "placeholder": "Select time to send congratulations"
        },
        "rus": {
          "label": "Unicode (All languages)",
          "hint": "Mark this field if your message contains not only latin characters. If unmarked, unavailable symbols will be replaced by \"?\" sign."
        }
      },
      "save": "Save"
    },
    "modalDelete": {
      "single": {
        "title": "Are you sure you want to delete \"{phone}\"?",
        "success": "Number \"{phone}\" deleted successfully"
      },
      "multiple": {
        "title": "Are you sure you want to delete the selected numbers?",
        "success": "Numbers successfully deleted"
      },
      "submit": "Delete",
      "cancel": "Cancel"
    },
    "membersForm": {
      "title": "Subscribers phone numbers and names",
      "placeholder": "{phone}; Alexander; 1987-05-23;М",
      "hint": "<strong>In the format:</strong> <br>phone number; name (if known); birthday (YYYY-MM-DD); gender (M or W)<br> <br><strong> For example:</strong> <br>{phone}; Alexander; 1987-05-23;М",
      "tip": "One entry per line",
      "submit": "Add to list",
      "success": "Nothing added to the list | {count} number added to the list | {count} numbers added to the list | {count} numbers added to the list"
    },
    "deleteListForm": {
      "title": "Remove numbers from other lists",
      "placeholder": "Select a list",
      "submit": "Delete from the lists",
      "success": "Nothing has been removed from the list | {count} number removed from the list | {count} numbers removed from the list | {count} numbers removed from the list"
    },
    "loadFromFileForm": {
      "title": "Load list",
      "file": {
        "title": "Drag and drop file here or click to select",
        "deleteFile": "Delete file",
        "errors": {
          "wrongFormat": "Wrong file format",
          "fileTooLarge": "File is too big"
        },
        "acceptFiles": "File format: {types}; Encoding: {charsets};",
        "additional": "One entry per line",
        "charset": {
          "label": "Encoding"
        }
      },
      "submit": "Start list processing",
      "success": "File processing queued"
    },
    "deleteMembersForm": {
      "title": "Delete numbers",
      "placeholder": "List of phone numbers in a column",
      "tip": "One entry per line",
      "submit": "Delete from the list",
      "success": "Nothing has been removed from the list | {count} number removed from the list | {count} numbers removed from the list | {count} numbers removed from the list"
    },
    "copy": {
      "title": "Copy phones to another list",
      "submit": "Copy",
      "close": "Отмена",
      "confirmAll": "Are you sure you want to copy all the numbers to the \"{list}\" list?",
      "confirmExclude": "Are you sure you want to copy all the numbers into the list \"{list}\" excluding the {count} numbers? | Are you sure you want to copy all the numbers into the list \"{list}\" excluding the {count} number? | Are you sure you want to copy all the numbers into the list \"{list}\" excluding the {count} numbers? | Are you sure you want to copy all the numbers into the list \"{list}\" excluding the {count} numbers?",
      "confirmInclude": "Are you sure you want to copy the {count} number into the list \"{list}\"? | Are you sure you want to copy the {count} number into the list \"{list}\"? | Are you sure you want to copy the {count} numbers into the list \"{list}\"? | Are you sure you want to copy the {count} numbers into the list \"{list}\"?",
      "informationAll": "All numbers will be copied to the list \"{list}\"",
      "informationExclude": "All numbers will be copied to the \"{list}\" list, except for:",
      "informationInclude": "{count} number will be copied to the list \"{list}\" | {count} number will be copied to the list \"{list}\" | {count} numbers will be copied to the list \"{list}\" | {count} numbers will be copied to the list \"{list}\"",
      "success": "Copied successfully"
    },
    "move": {
      "title": "Move phones to another list",
      "submit": "Move",
      "close": "Cancel",
      "confirmAll": "Are you sure you want to move all numbers to the \"{list}\" list?",
      "confirmExclude": "Are you sure you want to transfer all numbers to the list \"{list}\" excluding {count} numbers? | Are you sure you want to transfer all numbers to the list \"{list}\" excluding {count} number? | Are you sure you want to transfer all numbers to the list \"{list}\" excluding {count} numbers? | Are you sure you want to transfer all numbers to the list \"{list}\" excluding {count} numbers?",
      "confirmInclude": "Are you sure you want to move {count} numbers to the \"{list}\" list? | Are you sure you want to move {count} number to the \"{list}\" list? | Are you sure you want to move {count} numbers to the \"{list}\" list? | Are you sure you want to move {count} numbers to the \"{list}\" list?",
      "informationAll": "All numbers will be moved to the list \"{list}\"",
      "informationExclude": "All numbers will be moved to the \"{list}\" list, except for:",
      "informationInclude": "{count} numbers will be moved to the list \"{list}\" | {count} number will be moved to the list \"{list}\" | {count} numbers will be moved to the list \"{list}\" | {count} numbers will be moved to the list \"{list}\"",
      "success": "Moved successfully"
    },
    "transferForm": {
      "placeholder": "Enter list name",
      "label": "List name",
      "noOptionsText": "The list is empty. Specify your request",
      "noResultsText": "Nothing found"
    },
    "listMembers": {
      "phones": "Phones",
      "exclude": "Exceptions"
    },
    "editMember": {
      "title": "Edit number {phone}",
      "close": "Close",
      "cancel": "Cancel",
      "save": "Save changes",
      "success": "Saved successfully",
      "form": {
        "phone": "Phone",
        "name": "Name",
        "gender": "Gender",
        "birthday": "Date of birthday",
        "comment": "Comment"
      }
    },
    "filters": {
      "phone": {
        "label": "Phone",
        "placeholder": "Search by phone"
      },
      "memberName": {
        "label": "Name",
        "placeholder": "Search by name"
      },
      "gender": {
        "label": "Gender",
        "placeholder": "Select gender"
      },
      "birthday": {
        "label": "Birthday"
      }
    }
  },
  "messagesLog": {
    "title": "Message log",
    "spent": "Spent: { totalCosts } {currency}",
    "totalMessages": "{count} message | {count} messages",
    "fields": {
      "id": "Message ID",
      "sendDate": "Send \ndate",
      "phone": "Phone",
      "message": "Message",
      "deliveryDate": "Date of \nstatus change",
      "submissionDate": "Submission \ndate",
      "status": "Status",
      "cost": "Cost",
      "originator": "Sender \nname",
      "ip": "IP",
      "channel": "Channel",
      "departmentName": "Account",
      "batchName": "Name of \nmessaging",
      "error": "Error",
      "countryName": "Country",
      "readDate": "Read \ndate",
      "operatorName": "Mobile \noperator",
      "trafficType": "Traffic \ntype"
    },
    "filters": {
      "phones": "Phone number",
      "originator": "Sender name",
      "type": "Message type",
      "status": "Status",
      "channel": "Channel",
      "department": "Accounts",
      "mainLogin": "[Main login]",
      "batch": "Name of messaging",
      "batchPlaceholder": "Enter the name of messaging batch"
    },
    "form": {
      "errorPhonesValidate": "Phone numbers must contain numbers only",
      "endDateLargerStartDate": "The start date cannot be greater than the end date",
      "searchByDateWithoutPhones": "The search interval should not exceed 1 month. If you specify a phone number, you can specify an interval of up to 3 months",
      "isTooLongDateToRequest": "To download messages for more than 3 months, you need to download in csv/zip format",
      "phonesInputNumber": "To add several phones at once, write or put them through to comma"
    },
    "status": {
      "delivered": "Delivered",
      "notDelivered": "Undelivered",
      "read": "Read",
      "clicked": "Clicked",
      "checked": "Checked"
    },
    "messageStatus": {
      "delivered": "Delivered",
      "expired": "Expired",
      "deleted": "Deleted",
      "undelivered": "Undelivered",
      "buffered": "In process",
      "unknown": "Unknown",
      "rejected": "Rejected",
      "read": "Read",
      "timeout": "Timeout",
      "clicked": "Clicked",
      "checked": "Checked",
      "noData": "Accepted"
    },
    "messageError": {
      "1": "The subscriber is switched off or out of the coverage",
      "2": "Call barred",
      "3": "Unknown subscriber",
      "4": "Memory capacity exceeded",
      "5": "Equipment protocol error",
      "6": "Teleservice not provisioned",
      "7": "Facility not supported",
      "8": "Subscriber busy for MT SMS",
      "9": "Roaming restriction",
      "10": "SMSC response timeout",
      "11": "Routing error",
      "12": "System failure",
      "13": "Internal routing error",
      "14": "Illegal Subscriber",
      "15": "Message waiting list full",
      "16": "Incorrect A-name",
      "17": "Unroutable message"
    },
    "tabs": {
      "messagesLog": "Message log",
      "reports": "Requested reports"
    },
    "messageStatusHelp": {
      "close": "Close",
      "title": "SMS status reference",
      "subtitle": "All statuses, except \"<strong>Accepted</strong>\" and \"<strong>No data</strong>\" are final and won't changed.",
      "delivered": {
        "title": "Delivered",
        "content": "The message was delivered to the subscriber. It doesn't mean that the subscriber has read the message. The status may not be final if the channel supports the \"<strong>Clicked</strong>\" or \"<strong>Read</strong>\" status."
      },
      "clicked": {
        "title": "Clicked",
        "content": "Can be assigned to a message if the corresponding service is activated on the account and the channel supports this status. The status is assigned to a message after the subscriber clicks on the special link upgraded by SMS Traffic platform."
      },
      "undelivered": {
        "title": "Undelivered",
        "content": "The message was not delivered to the recipient, the redelivery attempts will not being taken. This is usually due to one of the following reasons: <ol> <li>the number is invalid or no longer in use,</li> <li>the subscriber is blocked for non-payment,</li> <li>the subscriber has the function \"call barring\" switched on.</li></ol>"
      },
      "expired": {
        "title": "Expired",
        "content": "The message was not delivered. Several attempts were made to deliver the message to the subscriber's phone, but they failed during the lifetime of the message.<br/> Possible reasons: <ol> <li>the mobile phone was switched off for a lifetime period of the message,</li> <li>the phone's message memory was full and the subscriber did not free up the memory during the lifetime of the message,</li> <li>multiple failures in cellular network equipment when trying to send a message to a subscriber.</li> </ol>"
      },
      "unknown": {
        "title": "Unknown",
        "content": "Message delivery status was not received from the SMS center.<br/> Possible reasons: <ol><li>failure on cellular network equipment,</li> <li>status information is not provided for the channel.</li></ol>"
      },
      "read": {
        "title": "Read",
        "content": "Status means that the subscriber has opened the message. </br>The «<strong>Read</strong>» status is supported by all channels except SMS."
      },
      "rejected": {
        "title": "Rejected",
        "content": "The message was not delivered. The message was rejected by the operator. <br/> Possible reasons: <ol> <li>invalid subscriber number,</li> <li>wrong sender name or sender name format</li> <li>wrong message format</li> <li>Rejected by the spam filter for some reason. It could be rejected due to too many attempts to send equal messages to same number (more than 4 messages in an hour by default).</li></ol>"
      },
      "deleted": {
        "title": "Deleted",
        "content": "The message has been deleted from the SMS center.<br/> Possible reasons: <ol> <li>the message cannot be routed due to restrictions on the operator's side,</li> <li>an error preventing further attempts to deliver the message to the target subscriber occurred while sending the message,</li> <li>in the case of an activated link shortening service, the status can be assigned to those segments that were not actually sent to the operator's network due to a decrease in the actual length of the SMS message.</li> </ol>"
      },
      "buffered": {
        "title": "Accepted",
        "content": "Attempts are made to deliver the message. This status is temporary and will change over time.<br /> Possible reasons: <ol> <li>the phone is switched off or out of network coverage,</li> <li>phone's message memory capacity is over limits,</li> <li>the current status of the message has not yet been received from the SMS center.</li> <ol>"
      },
      "noData": {
        "title": "No data",
        "content": "Waiting for termination. This status is temporary and will change over time. If the message has a send date, it means that no data has yet been received from the SMS center about the status of the message. If a message doesn't have a send date, it means it's still being delivered. This usually happens during delayed sending, when the time for sending this message has not yet come."
      },
      "checked": {
        "title": "Checked",
        "content": "The status means that the subscriber transferred in some way the information from the text of received message to the company that sent the message. For example, the subscriber have typed the numbers sent in the text of the message into the OTP field on the company's website."
      }
    },
    "reportGeneration": {
      "openButton": "Order a report in Csv/zip",
      "checkbox": "At least 1 field must be selected",
      "back": "Filters",
      "close": "Close",
      "dragHandlerTitle": "Drag-n-Drop",
      "title": "Report generation",
      "subtitle": "Report generation may take some time. Specify the address to which the notification of readiness will be sent.",
      "description": "The report will be available in the \"Message log\" section within 7 days",
      "explanation": "We will send the notification about report to your email",
      "fieldsTitle": "The report will contain fields:",
      "submit": "Start",
      "cancel": "Cancel",
      "form": {
        "email": "Email",
        "fileEncoding": "File Encoding"
      },
      "success": {
        "title": "Your request has been sent",
        "description": "We will send the notification about the report by email. The report will be available in the \"Message log\" section for 7 days",
        "cancel": "Close",
        "accept": "Reports"
      }
    }
  },
  "requestedReports": {
    "fields": {
      "genDate": "Generation date",
      "from": "Start date",
      "to": "End date",
      "departments": "Accounts",
      "phones": "Phones",
      "originator": "Sender",
      "type": "Message type",
      "channel": "Channel",
      "deliveryStatus": "Status",
      "batches": "Batch name",
      "format": "Format",
      "status": "Info",
      "phone": "phone"
    },
    "reportInfo": {
      "table": {
        "name": "Name",
        "date": "Date",
        "event": "Event",
        "header": "Event List",
        "noData": "This report has not been downloaded yet"
      },
      "eventStatus": {
        "download": "download",
        "create": "create"
      }
    },
    "reportStatus": {
      "new": "In queue",
      "completed": "Download",
      "processing": "Processing",
      "need_to_send_report": "Waiting for sending",
      "with_error": "Error"
    }
  },
  "ReportsOnRequest": {
    "pageTitle": "Reports on request",
    "emails": "email",
    "subject": "subject",
    "sentDate": "sent date",
    "files": "files",
    "report": "Report"
  },
  "DeliveryAnalysis": {
    "pageTitle": "Delivery analysis",
    "createAnalysis": "Create report",
    "back": "Back",
    "create": "Create",
    "noPhones": "No phones in the report",
    "departments": "Departments",
    "successMessage": "The report has been created, you will be notified when it is ready.",
    "failureInterval": "Active days",
    "dataDescriptions": "Specify a period of no more than 2 months",
    "failedDescriptions": "Phone numbers used in messaging during the specified period will be shown. At the same time, if during the specified period attempts to send to phone numbers were made in less than the specified number of days, then such numbers will not be included in the selection. This is done in order to exclude occasional or accidental hits of numbers in negative statistics.",
    "total": "Total",
    "download": "Download report",
    "fields": {
      "reportId": "id",
      "from": "Start date",
      "to": "End date",
      "creationDate": "Creation date",
      "departments": "Accounts",
      "failureInterval": "Failure interval",
      "deliveryStatus": "Status",
      "phone": "Phone",
      "failureDays": "Failure days",
      "failureMessages": "Undelivered messages"
    },
    "reportStatus": {
      "0": "Processing",
      "1": "Done"
    },
    "error": {
      "134": "Phones, satisfying this condition was not found."
    }
  },
  "statistics": {
    "title": "Statistics",
    "tabs": {
      "statistics": "Messaging statistics",
      "monthly": "Monthly analysis"
    },
    "groupBy": {
      "date": "Date",
      "originator": "Sender",
      "channel": "Channel",
      "messageType": "Message type",
      "month": "Month",
      "countryId": "Country",
      "operatorId": "Operator",
      "departmentId": "Account",
      "batchId": "Batch",
      "trafficType": "Traffic type"
    },
    "filters": {
      "date": "Date",
      "groupByTitle": "Group by",
      "messageType": "Message type",
      "channel": "Channel",
      "departments": "Accounts",
      "batch": "Batch name"
    },
    "filtersError": {
      "emptyGroup": "Check at least 1 checkbox",
      "rangeMoreThanOneYear": "You cannot select a range more than 1 year",
      "rangeMoreThanTwoMonth": "You cannot select a range more than 2 month",
      "endDateLargerStartDate": "The start date cannot be greater than the end date"
    },
    "column": {
      "delivered": "Delivered, msg.",
      "deliveredPercent": "Delivered, %",
      "read": "Read, msg.",
      "readPercent": "Read, %",
      "clicked": "Clicked, msg.",
      "clickedPercent": "Clicked, %",
      "total": "Total msg.",
      "sumCosts": "Spent, {currency}"
    },
    "summary": "Total",
    "downloadButton": "Download statistics in XLS"
  },
  "createAccount": {
    "title": "Create a new account",
    "link": "Accounts and departments",
    "successCreate": "Account created"
  },
  "accountForm": {
    "name": {
      "title": "Account name",
      "description": "This name will appear in all logs and documents",
      "label": "Account name",
      "placeholder": "Enter account name"
    },
    "PassStrength": {
      "formTitle": "Password policy",
      "formPlaceholder": "Choose a password policy",
      "default": {
        "label": "Default",
        "shortDescription": "At least 8 characters, at least 1 number and latin (a-z) letters in both cases",
        "description": "At least 8 characters, at least 1 number and latin (a-z) letters in both cases"
      },
      "weak": {
        "label": "Weak",
        "shortDescription": "Minimum 8 characters",
        "description": "Minimum 8 characters"
      },
      "complex": {
        "label": "Complex",
        "shortDescription": "At least 8 characters, at least 1 digit and Latin (a-z) letters of both cases, at least 1 special. symbol",
        "description": "At least 8 characters, at least 1 digit and Latin (a-z) letters of both cases, at least 1 special. symbol"
      }
    },
    "twoFactorAuthentication": {
      "title": "Two-factor authentication",
      "description": "Enter the phone number of the owner of this account in international format, without \"+\", for example, {phone}",
      "label": "Phone number",
      "placeholder": "Enter phone number"
    },
    "login": {
      "title": "Login",
      "description": "With this login, the user will be able to enter Private Area",
      "label": "Login",
      "placeholder": "Enter login"
    },
    "defaultOriginator": {
      "title": "Default sender",
      "description": [
        "Default sender name for SMS-Messaging",
        "should be unique"
      ],
      "label": "Default sender name",
      "placeholder": "Enter default sender name"
    },
    "password": {
      "title": "Password",
      "description": "Password",
      "label": "Password",
      "placeholder": "Enter password"
    },
    "oldPassword": {
      "title": "Old Password",
      "description": "Fill in the 'Old password' field",
      "label": "Old Password",
      "placeholder": "Old Password",
      "newOldplaceholder": "Enter a new password",
      "newRepeatOldplaceholder": "Repeat new password"
    },
    "passwordRules": {
      "tooBig": "Password cannot be more than 25 characters",
      "tooSmall": "Password must contain at least 8 characters",
      "theSame": "Password must match",
      "empty": "Password must be filled",
      "default": "Password",
      "weak": "enter password",
      "complex": "enter password"
    },
    "passwordConfirmation": {
      "description": "Repeat password",
      "label": "Repeat password",
      "placeholder": "Repeat password"
    },
    "roles": {
      "title": "Account rights"
    },
    "timezone": {
      "title": "Account timezone",
      "country": {
        "label": "Country",
        "placeholder": "Select country"
      },
      "timezone": {
        "label": "Timezone",
        "placeholder": "Select timezone",
        "noOptionsText": "Select country to see available timezones"
      }
    },
    "buttonSubmit": "Save",
    "validation": {
      "emptyField": "this field cannot be empty"
    }
  },
  "rolesHints": {
    "7": "The account will not be able to view the message text in the log",
    "9": "The account will not be able to send messages, but will be able to create batches that require subsequent moderator approval to send.",
    "8": "The account will be able to view the texts of the messages, but all the numbers in the texts will be replaced with the symbol *"
  },
  "editAccount": {
    "title": "Edit account",
    "link": {
      "account": "Accounts and departments",
      "depUser": "Departments users"
    },
    "tabs": {
      "settings": "Account settings",
      "privacy": "Privacy",
      "security": "Security",
      "changePassword": "Change password"
    },
    "sections": {
      "privacy": {
        "form": {
          "viewMessages": {
            "title": "View messages",
            "description": {
              "empty": {
                "subUser": "Account user can view messages without any restrictions",
                "depUser": "The user can only view messages within their department"
              },
              "filled": "Account user can view the messages of accounts from the list",
              "disabled": "The user does not have access to view messages. List not used"
            }
          },
          "approveBatches": {
            "title": "Confirmation of batches in moderation status",
            "description": {
              "empty": "The user does not have access to confirmation of batches in moderation status",
              "filled": "The user can confirm batches in moderation status of accounts from the list",
              "disabled": "The user does not have access to edit messaging batches. List not used"
            }
          },
          "editBatches": {
            "title": "Edit batches",
            "description": {
              "empty": "The list is empty, the user can only edit their own batches",
              "filled": "The user can edit batches of accounts from the list",
              "disabled": "The user does not have access to edit batches. List not used"
            }
          },
          "viewGroups": {
            "title": "View subscribers list",
            "description": {
              "empty": {
                "subUser": "The user can only view their own and public subscribers lists",
                "depUser": "The user can only view their department's subscribers lists and public subscribers lists"
              },
              "filled": "The user can view subscribers lists of accounts from the list"
            }
          }
        },
        "multiselect": {
          "groups": {
            "mainAccount": "Main login",
            "accounts": "Accounts",
            "departments": "Departments"
          }
        }
      },
      "changePassword": {
        "title": "Password",
        "form": {
          "password": {
            "description": "New password",
            "label": "Password",
            "placeholder": "Enter password"
          },
          "passwordConfirmation": {
            "description": "Confirm password",
            "label": "Confirm password",
            "placeholder": "Type again password"
          }
        }
      },
      "security": {
        "title": "Security",
        "form": {
          "nTypes": {
            "description": "Number of incorrect password entries:"
          },
          "inactivityPeriod": {
            "description": "Block an account when not using it after:"
          },
          "authTimeout": {
            "description": "Account session termination due to inactivity after:"
          },
          "passwordPeriod": {
            "description": "Password lifetime:"
          },
          "allowedGates": {
            "description": "Access limitation:"
          },
          "maxSessions": {
            "description": "Maximum number of open sessions:"
          }
        },
        "select": {
          "defaultValue": "No limitations",
          "allowedGatesValues": {
            "lkOnly": "Web only",
            "apiOnly": "API only"
          }
        }
      }
    },
    "buttonSubmit": "Save",
    "successEdit": "Changes saved"
  },
  "twoFactorAuth": {
    "notEnabled": "Not activated"
  },
  "editDepartment": {
    "title": "Edit",
    "link": "Accounts and departments",
    "tabs": {
      "settings": "Department settings",
      "accounts": "Accounts list"
    },
    "sections": {
      "settings": {
        "form": {
          "name": {
            "title": "Department name",
            "description": "This name will appear in all logs and documents",
            "label": "Department name",
            "placeholder": "Enter department name"
          },
          "login": {
            "title": "Login",
            "description": "With this login, the user will be able to enter Private Area",
            "label": "Login",
            "placeholder": "Enter login"
          }
        }
      },
      "accounts": {
        "title": "Accounts related to"
      }
    },
    "buttonSubmit": "Save",
    "successEdit": "Changes saved"
  },
  "batches": {
    "title": "Scheduled messages",
    "createBatchLink": "Start messaging",
    "tabs": {
      "scheduled": "Scheduled batches of messages",
      "archived": "Archive of batches"
    },
    "actions": {
      "start": "Start",
      "pause": "Pause",
      "cancel": "Cancel",
      "approve": "Approve"
    },
    "tableActionsLabel": "Actions with selected",
    "successStateChange": "There are no batches suitable for status change | The status of {count} batch has been successfully changed | The status of {count} batches has been successfully changed | The status of {count} batches has been successfully changed",
    "parentsBatches": {
      "all": "All bathes"
    },
    "table": {
      "columns": {
        "interval": "Interval of messaging",
        "progress": "Phone numbers processed",
        "name": "Name of batch",
        "originator": "Sender name",
        "message": "Text",
        "status": "Status",
        "batchesGroups": "Subscribers lists used in batch",
        "sendDate": "Send date",
        "type": "Messages type",
        "ip": "IP address"
      }
    },
    "filters": {
      "date": "Date",
      "status": "Status"
    },
    "status": {
      "new": "New",
      "processing": "In process",
      "canceled": "Canceled",
      "paused": "Paused",
      "finished": "Finished",
      "expired": "Expired",
      "premoderate": "On moderation"
    },
    "messageType": {
      "smartDelivery": "Smart Delivery",
      "sms": "SMS"
    },
    "detailedStatus": {
      "totalPhones": "Total phones",
      "percent": "Sent",
      "success": "Sent ({n} msg.)",
      "failed": "Failed ({n} msg.)"
    },
    "details": {
      "title": "Batch details",
      "closeHint": "Close",
      "closeButton": "Close",
      "editButton": "Edit",
      "labels": {
        "status": "Status",
        "name": "Name",
        "type": "Messages type",
        "originator": "Sender",
        "creationDate": "Creation date",
        "startDate": "Start Date",
        "breakTime": "Stop time",
        "localTime": "Use local time",
        "gap": "Gap between messages",
        "endDate": "End date",
        "totalPhones": "Total phone numbers",
        "sentPhones": "Sent phone numbers",
        "blacklist": "Black list",
        "message": "Message text",
        "buttonName": "Button name",
        "buttonUrl": "Button URL",
        "image": "Image"
      },
      "undefinedValue": "Undefined",
      "localTime": {
        "true": "Taken into account",
        "false": "Not used"
      }
    },
    "edit": {
      "validation": {
        "timeFormat": "Time format have to be HH:MM:SS",
        "sameStopAndStartTime": "The Start time cannot be equal to the Stop time",
        "timeInputLabel": "Time",
        "startDateLessThanCurrentDate": "The start date cannot be less than the current date",
        "startDateMoreThan30Days": "The start date cannot be more than 30 days from the current date"
      },
      "title": "Edit batch",
      "form": {
        "name": {
          "label": "Batch name",
          "placeholder": "Enter batch name"
        },
        "originator": {
          "label": "Sender",
          "placeholder": "Enter sender name"
        },
        "period": {
          "title": "Start time",
          "startDate": "Start date",
          "startTime": "Start time"
        },
        "isLocalTime": "with local time taken into account",
        "suspend": {
          "switch": "Pause batch",
          "endTime": "Pause batch at",
          "startTimeNextDay": "Resume sending the next day at"
        },
        "speed": "Sending rate",
        "speedUnits": "msg./min.",
        "gap": "Gap between messages",
        "timeout": "Message lifetime",
        "rus": "Unicode",
        "channels": {
          "message": {
            "title": "Message",
            "label": "Message text",
            "placeholder": "Enter message text"
          },
          "buttonName": {
            "label": "Button text",
            "placeholder": "Enter button text"
          },
          "buttonUrl": {
            "label": "Button URL",
            "placeholder": "Enter button URL"
          },
          "image": "Image file",
          "noImage": "No file selected"
        }
      },
      "submitButton": "Save",
      "cancelButton": "Cancel",
      "successEdit": "Changes saved"
    }
  },
  "batchCreate": {
    "title": "Send messages",
    "selectDraft": {
      "title": "Message draft",
      "empty": "Draft not selected",
      "button": "Select a draft"
    },
    "selectDrawer": {
      "title": "Find template",
      "submit": "Select template",
      "clear": "Clear form",
      "cancel": "Cancel",
      "closeText": "Close"
    },
    "form": {
      "name": {
        "title": "Batch name",
        "placeholder": "Enter batch name",
        "description": "If the planned messaging is not great (will last no more than five minutes from the moment the data was sent on this page), then the messages will be saved only in the log. The name for such messaging is not required."
      },
      "timeout": "Message lifetime",
      "timeSeconds": "Time in seconds",
      "linkHttp": "Link have to contain http:// or https:// prefix",
      "linkRegex": "The link must contain the prefix .com .net .ru or others",
      "phonesSource": {
        "title": "Subscribers",
        "select": {
          "label": "Phone numbers",
          "options": {
            "commaSeparated": "Comma separated list of phones",
            "lineSeparated": "List of phones in a column, one phone per line",
            "lineSeparatedWithMessages": "Phone-message list, one pair per line",
            "lineSeparatedFile": "List of phones from file",
            "lineSeparatedFileWithMessages": "Phone-message list file",
            "lineSeparatedWithParamsFile": "File with a list of message parameters"
          }
        },
        "errorTable": {
          "rowNumber": "#",
          "errorNumber": "error",
          "row": "row",
          "added": "added",
          "errors": "errors",
          "allRows": "rows",
          "size": "size",
          "downloadAllErrors": "download all {count} errors in a file",
          "tableErrorHead": "the errors found in the file",
          "errorCodes": {
            "100": "Invalid string",
            "101": "Incorrect column headings",
            "200": "Empty message",
            "300": "Phone number is too short",
            "301": "Phone number is too long",
            "302": "Invalid phone number length",
            "303": "Incorrect phone number",
            "400": "Incorrect number of parameters"
          }
        },
        "blocks": {
          "commaSeparated": {
            "label": "Your list",
            "placeholder": "Enter comma separated list of phones"
          },
          "lineSeparated": {
            "label": "Your list",
            "placeholder": "Enter list of phones in a column, one phone per line"
          },
          "lineSeparatedWithMessages": {
            "label": "Your list",
            "placeholder": "Enter space-separated phone-message list, one pair per line"
          },
          "selectedGroups": {
            "label": "Selected subscribers list"
          },
          "selectedPhones": {
            "label": "Selected subscribers list",
            "excluded": "Excluded"
          },
          "lineSeparatedFile": {
            "label": "",
            "charset": {
              "label": "Encoding"
            },
            "file": {
              "description": "Each phone number on a new line",
              "loading": "File loading in progress"
            }
          },
          "lineSeparatedWithMessagesFile": {
            "label": "",
            "charset": {
              "label": "Encoding"
            },
            "file": {
              "description": "Each phone-message pair on a new line",
              "loading": "File loading in progress"
            }
          },
          "lineSeparatedWithParamsFile": {
            "label": "",
            "charset": {
              "label": "Encoding"
            },
            "file": {
              "description": "Each entry on a new line",
              "loading": "File loading in progress"
            }
          }
        },
        "validation": {
          "textarea": "Phone numbers list",
          "file": {
            "required": "File is required",
            "loading": "Wait for the file to finish uploading"
          }
        },
        "blackList": {
          "label": "Black list",
          "description": "No messaging will be made to these numbers",
          "defaultOptionLabel": "Not selected",
          "noSelected": "Choose a list"
        },
        "ignorePhoneFormat": "Do not stop messaging if the data contains an incorrect number"
      },
      "message": {
        "title": "Message",
        "isSameMessages": "Same messages for every channel",
        "description": "Select messaging route and enter a sender name",
        "route": {
          "label": "Route",
          "description": "If the message is not delivered, we can send it through another service"
        },
        "originator": {
          "label": "Sender name",
          "description": "Maximum 11 characters, use latin letters and numbers",
          "validation": "Latin letters, numbers, spaces and symbols ,!.&*?,\"':_- are allowed"
        },
        "channels": {
          "common": {
            "textarea": {
              "label": "Message text",
              "placeholder": "Enter message text"
            }
          },
          "sms": {
            "rus": "Unicode"
          },
          "viber": {
            "image": "Viber image",
            "button": "Viber button",
            "description": "Label on the button and the link",
            "buttonName": {
              "label": "Label on the button",
              "placeholder": "Enter label on the button"
            },
            "buttonUrl": {
              "label": "Link",
              "placeholder": "Enter link to go when clicked"
            }
          },
          "whatsapp": {
            "image": "Whatsapp image"
          }
        },
        "file": {
          "title": "Drag&drop file here or click to select",
          "acceptFiles": "File in format: {types};",
          "additional": "Size not more than {size} kb",
          "delete": "Delete",
          "errors": {
            "WrongFormat": "Wrong file format",
            "FileTooLarge": "File is too big"
          }
        }
      },
      "period": {
        "title": "Messaging period",
        "switch": "Send now",
        "description": "If not all messages have time to leave by the specified time, the messaging will be automatically stopped",
        "startDate": "Start date",
        "startTime": "End date",
        "timeout": "Message lifetime",
        "stopTime": "Stop messaging",
        "endTime": "Stop messaging at",
        "startTimeNextDay": "Resume messaging next day at",
        "localtime": "Correct start date considering the subscriber local time"
      },
      "sendingSpeed": {
        "title": "Messaging rate",
        "switch": "Send evenly",
        "speed": "Messaging rate",
        "gap": "Gap between messages",
        "gapTime": "Send evenly till"
      },
      "continueButton": "Continue",
      "testButton": "Test your message"
    },
    "hint": {
      "phonesSource": {
        "commaSeparated": "Separated by commas, in international format, for example:<br/>{phone1}, {phone2}<br/><br/><b>Suitable for all routes</b>",
        "commaSeparatedSelfRegistered": {
          "text": "By default, only the number from which registration was made is available for testing. <br/><br/> To add a phone number to the list for SMS testing, send a message to <b>9395</b> with the text \"<b>optin {login}</b>\".<br/ ><br/> Message to number 9395 is free.<br/><br/>",
          "buttonInfo": "See why you need it and read the full instructions",
          "drawer": {
            "title": "Why do I need to verify the number?",
            "close": "Close",
            "text": "Verification of each phone number in the demo version is required to comply with telecommunications and advertising laws. In the full version, you won't need to confirm every number to be added to the messaging list. <br/><br/> <b>Instructions for adding a number</b><br/> <ol> <li>Send a message to <b>9395</b> (im Russia only) <b>optin&nbsp;{login}< /b> </li> <li>In case of successful confirmation, you will receive a confirmation message from the SMS Traffic sender name</li> </ol>"
          }
        },
        "lineSeparated": "In a column, one phone number in international format per line, for example:<br/>{phone1}<br/>{phone2}<br/><br/><b>Suitable for all routes</b>",
        "lineSeparatedWithMessages": "In a column, in the format \"phone, space, message text\", one message per line, for example:<br/>{phone1} message text 1<br/>{phone2} message text 2<br/><br/><b>Suitable only for SMS route</b>",
        "selectedGroups": "Selected subscribers list",
        "selectedPhones": "Selected subscribers list",
        "lineSeparatedFile": "In a column, one phone number in international format per line, for example:<br/>{phone1}<br/>{phone2}<br/><br/><b>Suitable for all routes</b>",
        "lineSeparatedWithMessagesFile": "In a column, in the format \"phone, space, message text\", one message per line, for example:<br/>{phone1} message text 1<br/>{phone2} message text 2<br/><br/><b>Suitable only for SMS route</b>",
        "lineSeparatedWithParamsFile": "In the format <br/><b>parameter name1;parameter name2;phone;parameter name3;...</b>...<br/>parameter1;parameter2;phone number;parameter3;...< br/><br/>One set of parameters for one phone number per line, for example, for a message with parameters:<br/>\"Hello [[name]]! We remind you that you have a [[percent]]% discount\"<br/><br/> The file with parameters should look like this: <br/>phone;percent;name<br/>{phone1};10;Andrey<br/>{phone2};15;Maria<br/><br/><b>Suitable for all routes</b>"
      },
      "message": "Each channel has its own restrictions and features when sending messages. Also, if you choose to upload messaging list of subscribers as a file with parameters, you can use the parameters in the text to personalize the message.<br/><br/><b>For example:</b><br/>Hello [[name]]! <br/>We remind you that you have a [[percent]]% discount"
    },
    "successMessage": {
      "count": "Messages for { count } subscriber have been | Messages for { count } subscribers have been ",
      "message": "queued for sending. Approximate delivery time"
    },
    "confirmationDrawer": {
      "close": "Close",
      "title": "Messaging details",
      "subtitle": "Check the details of messaging you entered",
      "messageType": "Message type",
      "rus": "Unicode",
      "originator": "Sender name",
      "channel": "Channel",
      "messageCount": "Number of messages",
      "message": "Message text",
      "batchName": "Subscribers list",
      "blackList": "Black list",
      "phonesCount": "Number of phones",
      "startDate": "Start date",
      "startTime": "Start time",
      "buttonText": "Text button",
      "buttonUrl": "Link button",
      "buttonImage": "Image",
      "speed": "Rate",
      "submit": "Send",
      "cancel": "Cancel",
      "inRussian": {
        "yes": "Yes",
        "no": "No"
      }
    },
    "shortenUrl": {
      "title": "URL shortener is switched on",
      "subtitle": "The message will be like this:"
    },
    "testDrawer": {
      "title": "Test your message",
      "close": "Close",
      "testPhones": "Phone numbers",
      "hint": "Enter phone numbers in international format separated by commas",
      "resultTitle": "Test messaging result",
      "totalPhones": "Messages sent",
      "submit": "Send",
      "cancel": "Cancel"
    },
    "preliminaryEvaluation": {
      "table": {
        "direction": "direction",
        "price": "price",
        "size": "size",
        "amount": "amount",
        "allAmount": "all amount"
      },
      "credits": "message | messages",
      "init": {
        "title": "Preliminary cost estimate",
        "subTitle": "It may take a long time",
        "button": "Calculate"
      },
      "loading": {
        "title": "Calculating...",
        "subTitle": "It may take a long time",
        "button": "Cancel"
      },
      "success": {
        "subTitle": "Preliminary cost estimate",
        "button": "Calculate again"
      }
    }
  },
  "dateTimeForm": {
    "date": "Date",
    "time": "Time",
    "hours": "Hours",
    "minutes": "Minutes",
    "year": "Year",
    "month": "Month",
    "day": "Day"
  },
  "settings": {
    "title": "Settings",
    "tabs": {
      "general": "General settings",
      "security": "Security settings",
      "smtp": "SMTP v.1",
      "password": "Change password"
    },
    "general": {
      "successSaved": "Changes saved",
      "emailAddresses": "Email addresses for notifications",
      "emailNote": "Notifications about operators maintenance works and accidents will be sent to these email addresses. Addresses are entered separated by spaces.",
      "emailFinance": "List of addresses for receiving notifications about technical work on the platform, about technical changes and work on the side of the operators. Addresses are entered separated by spaces.",
      "emailTech": "A list of addresses to which notifications about changes in tariffs, the state of the account balance, exceeding limits will be sent. Addresses are entered separated by spaces.",
      "technicalEmail": "Technical email address",
      "financialEmail": "Financial email address",
      "administrativeEmail": "Administrative email address",
      "defaultOriginator": "Default sender name",
      "defaultOriginatorPlaceholder": "This name will appear in every messaging you send through our service",
      "alerts": "Alerts",
      "balanceAlertMinValueLabel": "End of funds alert",
      "balanceAlertMinValueNote": "A notification will be sent to the financial email address If there is less than the specified amount remaining before the balance is depleted.",
      "balanceRemindDaysLabel": "Balance alert",
      "balanceRemindDaysNote": "Specify the frequency in days from which balance notifications will be sent to the financial email address.",
      "sendMessages": "Messaging settings",
      "rusByDefaultDisabledLabel": "Do not set \"Unicode\" flag",
      "rusByDefaultDisabledSubtitle": "The \"Unicode\" option is enabled by default in the sending form while messaging",
      "rusByDefaultDisabledNote": "You can disable the automatic setting of this flag",
      "blackList": "Black list",
      "blackListNote": "Messages will not be sent to numbers from the selected list. Applying automatically for HTTP, SOAP protocols.",
      "smtp2Label": "Allow sending via HTTP, SOAP, SMTP v2 protocols",
      "smtp2Note": "Use standard protocols to send messages",
      "saveSettingsButton": "Save settings",
      "validation": {
        "email": "Addresses are not valid: {emails}"
      },
      "timezone": {
        "title": "Account timezone",
        "subtitle": {
          "text": "The timezone of other accounts can be changed in the section",
          "link": "Accounts and departments"
        },
        "country": {
          "label": "Country",
          "placeholder": "Select country"
        },
        "timezone": {
          "label": "Timezone",
          "placeholder": "Select timezone",
          "noOptionsText": "Select a country to display a list of timezones"
        }
      },
      "moNumbers": {
        "title": "Incoming numbers",
        "table": {
          "number": "Phone number",
          "monthPrice": "Monthly fee",
          "prefix": "Prefix",
          "email": "Email",
          "script": "Process by",
          "editButton": "Edit"
        },
        "editForm": {
          "closeEditForm": "Close",
          "title": "Incoming number settings edit",
          "number": "Number",
          "prefix": "Prefix",
          "submitButton": "Save",
          "group": "Subscribers list for adjunction",
          "groupZeroValue": "No list",
          "department": "Forward incoming messages to account:",
          "departmentZeroValue": "Do not forward",
          "script": {
            "label": "Process by",
            "options": {
              "http": "HTTP callback script",
              "smpp": "SMPP",
              "email": "Email"
            }
          },
          "scriptUrl": "HTTP script URL",
          "httpMethod": {
            "label": "Method",
            "options": {
              "get": "GET",
              "post": "POST"
            }
          },
          "email": "Email"
        }
      }
    },
    "security": {
      "form": {
        "title": "Email addresses",
        "label": "Accounts messaging events notification email addresses",
        "note": "Accounts messaging events notification will be sent to these addresses. Addresses are entered separated by commas.",
        "submitButton": "Save",
        "success": "Changes saved"
      },
      "title": "Sessions",
      "closeDrawer": "Close",
      "deleteSelectedSessions": "Delete selected sessions",
      "deleteSessionButton": "Delete",
      "cancelDeleteSessionButton": "Cancel",
      "deleteSessionsTitle": "Deleting sessions",
      "deleteQuestion": {
        "all": "Are you sure you want to delete all sessions?",
        "include": "Are you sure you want to delete these sessions?",
        "exclude": "Do you really want to delete all sessions except for:"
      },
      "table": {
        "createdAt": "Creation date",
        "ip": "IP",
        "login": "Account login"
      }
    },
    "smtp": {
      "smtp1": {
        "title": "SMTP v.1",
        "allowSendingEmail": "Allow sending SMS messages via SMTP v.1 protocol",
        "transliteration": {
          "label": "Translit SMS text",
          "additionalText": "The Russian text will not display correctly on phones that do not support it"
        },
        "hint": "If SMTP v.1 protocol is allowed to use, you must set up protection against unauthorized use of your account. It is possible to set protection by SMTP headers or by entering a limited list of phones to which messages can be sent."
      },
      "protectByHeaders": {
        "title": "SMTP header protection",
        "protectedHeaders": "Protection enabled for: ",
        "smtpAllowedFrom": "Protection enabled by domain in header for: ",
        "badHeadersAlertLabel": "Notify me about attempts to send a message with an invalid header. The notification will be sent to the technical notification addresses specified in the \"General settings\" section",
        "suggest": {
          "firstPart": "To set up protection by SMTP server headers, you need to send a test email message to",
          "email": "tech.support{'@'}smstraffic.ru",
          "secondPart": "through the same SMTP server through which real messages will be sent. Be sure to include your login and your request in the subject line of the email."
        }
      },
      "viaEmail": {
        "title": "Send to phone list only",
        "label": "Allowed numbers",
        "additionalText": "Phone numbers to which you can send messages via SMTP v.1",
        "allNumbers": "All numbers",
        "fromList": "Numbers from the list",
        "numbersLabel": "Your list"
      },
      "saveButton": "Save",
      "successSaved": "Changes saved"
    },
    "password": {
      "title": "Password",
      "expiredPasswordTitle": "Change Password",
      "expiredPasswordSubTitle": "To continue using your personal account, you must change your password",
      "form": {
        "oldPassword": "Old password",
        "password": "New password",
        "passwordConfirmation": "Confirm new password",
        "changeButton": "Save",
        "success": "New password saved"
      }
    }
  },
  "eventLogs": {
    "title": "Events log",
    "column": {
      "login": "Login",
      "eventDesc": "Event",
      "ip": "IP",
      "recordDate": "Event date"
    },
    "filters": {
      "date": "Date",
      "user": "Account",
      "mainAccount": "Main account",
      "users": "Accounts",
      "departmentUsers": "Department users"
    },
    "download": "Download events in XLSX"
  },
  "originators": {
    "title": "Sender names list",
    "column": {
      "originator": "Sender name",
      "operator": "Operator",
      "status": "Status",
      "addDate": "Add date",
      "approveDate": "Approve date",
      "deleteDate": "Delete date",
      "delete": "Delete"
    },
    "status": {
      "New": "New",
      "Failed": "Failed",
      "Deleted": "Deleted",
      "Approved": "Approved",
      "ToDelete": "Set for deletion",
      "WaitConfirm": "Waiting for confirmation",
      "WaitDeleted": "Waiting for deletion"
    },
    "createOriginator": {
      "close": "Close",
      "title": "Add sender name",
      "additionMethod": {
        "label": "Add via",
        "options": {
          "form": "Web form",
          "file": "File"
        }
      },
      "controls": {
        "originator": "Sender name",
        "companyName": "Company name",
        "inn": "Tax number",
        "operatorId": "Operator",
        "operatorPlaceholder": "Select operator",
        "file": {
          "name": "File",
          "title": "Drag&drop file here or click to select",
          "csv": "CSV: Sender name; company name; tax number",
          "xlsx": "XLSX: Sender name {'|'} Company name {'|'} Tax number"
        }
      },
      "submit": "Add",
      "success": "Sucessfully added"
    },
    "filters": {
      "originator": "Sender name",
      "operatorId": "Operator",
      "status": "Status"
    },
    "deleteConfirmation": {
      "title": "Do you really want to delete the sender name \"{ originatorName }\" from the operator \"{ operatorName }\"?",
      "acceptButton": "Delete",
      "cancelButton": "Cancel",
      "success": "Sender name successfully deleted"
    }
  },
  "timeZoneAlarm": {
    "title": "Please note:",
    "text": "Your operational system time zone {systemTimeZone} and your account time zone {timeZoneName} do not match. This may lead to misunderstanding of the displayed time through the private web interface. The time in an account is displayed according to the account settings, not your system. Please update the time zone settings in your",
    "link": "account settings"
  },
  "techDocs": {
    "title": "API and documentation",
    "fileNames": {
      "api": "SMS Messaging API",
      "api_kz": "SMS Messaging API",
      "api_com": "SMS Messaging API",
      "api_uz": "SMS Messaging API",
      "sd_api": "Smart Delivery Messaging API",
      "api_ritorica": "SMS Messaging API",
      "sd_api_ritorica": "Smart Delivery Messaging API",
      "lk_documentation": "Personal web area user manual",
      "sms_bulk": "SMS messaging methods",
      "sms_employee": "Service description SMS-employee",
      "voice_api": "API for Automated voice messages",
      "voice_documentation": "Voice Messages user manual",
      "code_sample": "Code examples",
      "imsi_api": "IMSI API (Ver.1.2)",
      "api_by": "SMS Messaging API",
      "sd_api_by": "Smart Delivery Messaging API",
      "sd_api_kz": "Smart Delivery Messaging API",
      "sd_api_com": "Smart Delivery Messaging API",
      "sd_api_uz": "Smart Delivery Messaging API",
      "lk_documentation_by": "Personal web area user manual",
      "lk_documentation_uz": "Personal web area user manual",
      "lk_documentation_kz": "Personal web area user manual",
      "lk_documentation_com": "Personal web area user manual",
      "sms_bulk_by": "SMS messaging methods",
      "sms_employee_by": "Service description SMS-employee",
      "voice_api_by": "API for Automated voice messages",
      "voice_documentation_by": "Voice Messages user manual",
      "code_sample_by": "Code examples",
      "imsi_api_by": "IMSI API (Ver.1.2)",
      "sms_bulk_uz": "SMS messaging methods",
      "sms_employee_uz": "Service description SMS-employee",
      "voice_api_uz": "API for Automated voice messages",
      "voice_documentation_uz": "Voice Messages user manual",
      "code_sample_uz": "Code examples",
      "imsi_api_uz": "IMSI API (Ver.1.2)",
      "sms_bulk_kz": "SMS messaging methods",
      "sms_employee_kz": "Service description SMS-employee",
      "voice_api_kz": "API for Automated voice messages",
      "voice_documentation_kz": "Voice Messages user manual",
      "code_sample_kz": "Code examples",
      "imsi_api_kz": "IMSI API (Ver.1.2)"
    },
    "columns": {
      "documentType": "File name",
      "pdf": "pdf",
      "doc": "word",
      "zip": "zip"
    },
    "download": "Download",
    "size": {
      "mb": "Mb",
      "kb": "Kb"
    }
  },
  "accountingInfo": {
    "title": "Company details",
    "organization": {
      "title": "Company",
      "name": "Company name",
      "director": "Manager"
    },
    "contacts": {
      "title": "Contact information",
      "phone": "Phone",
      "addressJure": "Legal address",
      "fax": "Факс",
      "addressFact": "Actual address",
      "addressPost": "Post address"
    },
    "requisites": {
      "title": "Bank details",
      "bankName": "Bank name",
      "rSchet": "Account number",
      "korSchet": "Correspondent account",
      "inn": "Tax number",
      "bik": "BIC (Bank Identification Code)",
      "kpp": "Tax Registration Reason Code"
    },
    "accountingPeriod": {
      "title": "Invoices are issued { period }",
      "mn": "monthly",
      "kv": "quarterly",
      "h": "once every six months",
      "y": "once a year",
      "no": "after payment"
    }
  },
  "accounting": {
    "title": "Accounting documents",
    "printButton": "Print selected",
    "successSendData": "Data Sent to EDM",
    "edoTitle": "EDM",
    "send": "Send",
    "organization": "Organization",
    "department": "Department",
    "column": {
      "enterDate": "Date",
      "recordId": "Number",
      "sumSNds": "Amount",
      "viewTypes": "Documents",
      "documentStatus": "Status"
    },
    "documentStatus": {
      "paid": "Paid",
      "not_paid": "Not paid",
      "sentToEdm": "Sent to EDM",
      "numberDocument": "Document number",
      "docStatus": {
        "-1": "Generated, not loaded",
        "0": "Loaded into the CED",
        "2": "Loaded into the CED",
        "3": "Departure",
        "4": "Received by client",
        "6": "Error sending",
        "9": "Received by client",
        "7": "Signed by the client",
        "19": "Withdrawn",
        "20": "Deleted",
        "22": "Cancelled",
        "23": "Waiting for client signature",
        "27": "Pending cancellation",
        "28": "Request request error",
        "29": "Unknown status",
        "null": "Not formed"
      }
    },
    "viewTypes": {
      "schet": "Invoice",
      "act": "Deed",
      "factura": "Tax invoice",
      "all": "All"
    },
    "filters": {
      "date": "Date",
      "documentStatus": "Status"
    }
  },
  "transactionsHistory": {
    "title": "Transactions history",
    "columns": {
      "transactionDate": "Date",
      "transactionType": "Transaction Type",
      "deltaRur": "Amount",
      "delta": "Amount",
      "deltaUsd": "Balance changes",
      "deltaCredits": "Number of messages",
      "notes": "Notes"
    },
    "transactionTypes": {
      "deposit": "Deposit",
      "payment": "Payment",
      "testing": "Testing",
      "testing over": "Testing over",
      "refund": "Refund",
      "conversion": "Inside conversion",
      "transfer": "Transfer to another account",
      "fine": "Fine",
      "tariff change": "Tariff change",
      "correction": "Correction",
      "refund_advance": "Refund advance payment",
      "netting": "Offsetting",
      "abon_plata": "Subscription fee",
      "other": "Other",
      "cyberplat payment": "Cyberplat Payment"
    },
    "closeText": "Close",
    "closeButton": "OK",
    "transactionsInfo": {
      "title": "Payment information dated { date }"
    },
    "filters": {
      "date": "Date",
      "transactionType": "Transaction type"
    }
  },
  "issueBill": {
    "title": "Issue an invoice",
    "description": "{ amount } { currency } minimum (including taxes)",
    "amount": {
      "placeholder": "Input amount",
      "label": "Amount"
    },
    "submit": "Issue",
    "validate": {
      "amount": "Please input amount more than { amount } { currency }"
    },
    "success": "An invoice for { amount } { currency } successfully issued"
  },
  "dashboard": {
    "greeting": "Hello",
    "createBatch": {
      "title": "Start messaging",
      "description": "Send messages using any channel available to your account",
      "link": "Send messages"
    },
    "createGroup": {
      "title": "Add new subscribers list",
      "description": "Set up your subscribers list to speed up the messaging process",
      "link": "Create a subscribers list"
    },
    "selfRegisteredUserScreen": {
      "greeting": {
        "title": "Welcome!",
        "description": [
          "In the demo version of your personal account, you can check out the main features in a limited mode and send 30 test SMS to the phone numbers you confirmed.",
          "We had to limit our mailing lists, flexible role system, API, Smart Delivery, and limit the list of numbers possible to send messages to in order to comply with the requirements of communications and advertising laws."
        ]
      },
      "accessing": {
        "title": "Access to full version",
        "description": "In the full version, you will have access to all the functionality of your personal area and will not need to confirm each number to add to the list.",
        "button": "Get an access"
      },
      "drawer": {
        "content": "To get access to the full version of your personal area, you need to send us the following data to email <a href='mailto:{ email }'>{ email }</a>: <ol> <li>Your login in our system</li> <li>Details of your legal entity</li> <li>Required name of the sender on which behalf you plan to send messages</li> <li>Supporting documents for the name of the sender (for example, it could be a trademark registration certificate or a domain registration certificate name)</li></ol>",
        "close": "Close"
      }
    },
    "contacts": {
      "title": "Contacts",
      "manager": "Personal manager",
      "supportText": "Technical support",
      "phone": "Phone",
      "email": "Email"
    },
    "sessions": {
      "title": "Sessions",
      "link": "All sessions"
    },
    "historyNumbers": {
      "title": "History in numbers",
      "thisMonth": "Total { type } from { from } to { to }",
      "lastMonth": "Total { type } from { from } to { to }",
      "documents": "Invoices for { month } in the amount { sum } { currency }, status",
      "sms": "SMS",
      "messages": "Messages"
    },
    "batches": {
      "title": "Batches on moderation",
      "link": "All batches"
    }
  },
  "tasksSidebar": {
    "open": "Open notices",
    "close": "Close",
    "title": "Notifications",
    "empty": "You don't have notifications",
    "delete": "Remove",
    "deleteSuccess": "Notification successfully removed",
    "taskType": {
      "group_load": "Uploading a file to a subscribers list"
    },
    "status": {
      "new": "In the queue",
      "processed": "Processing",
      "paused": "Paused",
      "canceled": "Cancelled",
      "finished": "Finished",
      "error": "Error"
    },
    "taskInfo": {
      "groupName": "Subscribers list",
      "totalRows": "Total rows in the list",
      "affectedRows": "Rows processed",
      "insertedRows": "Rows inserted",
      "error": "Error",
      "empty": "No details",
      "createdAt": "Creation date",
      "createdAtTime": "Creation time"
    }
  },
  "phoneConfirmation": {
    "title": "Phone number verification",
    "note": "Enter your phone number and we will send you a confirmation code to continue registration",
    "button": "Continue"
  },
  "passwordConfirmation": {
    "title": "Registration confirmation",
    "password": "Password",
    "passwordRepeated": "Confirm password",
    "button": "Complete registration"
  },
  "carousel": {
    "slide-1": "Effective use of Messaging",
    "slide-2": "Push Notifications",
    "slide-3": "Vkontakte and Odnoklassniki messages",
    "slide-4": "Voice messaging supported by all mobile devices",
    "slide-5": "WhatsApp Business API with customer feedback",
    "slide-6": "Email marketing for business",
    "slide-7": "Calls to Viber Out for your business"
  },
  "extra": {
    "goToOldFront": "To Old Front",
    "ReasenGoToTextArea": "the Reason for moving",
    "theReasenGoToOldFront": "Please indicate the reason for switching to the old version of your personal account, this will help us improve the service."
  }
}
